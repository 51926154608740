<template>
  <v-sheet  :color="backgroundColor || wsWHITE" class="d-flex justify-center align-center"
            :min-height="minHeight"
  >

    <v-sheet :color="backgroundColor || wsWHITE"
             width="100%"
             :max-width="!fullWidth ? width : null"

    >

      <!-- Title-->
      <slot name="title">
        <h1 v-if="title"
            style="max-width: 1000px; margin-left: auto; margin-right: auto;"
            class="text-center"
            :class="[{'px-3' : SM}]"
            :style="!SM ? `font-size: 32px; line-height: auto; color : ${wsDARKER}` : `font-size: 19px; line-height: 1.5; color : ${wsDARKER}`"
        >
          {{ $t(title) }}
        </h1>
      </slot>
      <!-- Subtitle-->
      <slot name="subtitle">
        <p v-if="subtitle" :style="`color : ${wsDARKER}`"
           style="max-width: 780px; font-size: 16px"
           class="font-weight-regular mx-auto text-center px-6"
           :class="!doubleSubtitlePadding ? 'mt-2' : 'mt-10'"
        >
          {{ $t(subtitle) }}
        </p>
      </slot>
      <!-- :: MAIN CONTENT :: -->
      <slot name="default"></slot>
    </v-sheet>

  </v-sheet>
</template>

<script>
export default {
  name: "homePageBlock",
  props : {
    fullWidth : {
      type : Boolean,
      default : false
    },
    width : {
      type : String,
      default : '1140'
    },
    minHeight : {
      type : String,
    },
    backgroundColor : {
      type : String,
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    title : {
      type : String
    },
    subtitle : {
      type : String
    },
    doubleSubtitlePadding : {
      type : Boolean,
      default : false
    }
  }
}
</script>

<style scoped>

</style>